/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';

import { CustomModal, Input, Button, Toast } from '@/components';
import CodeInput from '@/pageComponents/Login/CodeInput';
import { EmailCodeLoginReq, LoginRes } from '@/api/types/login';
import { forgetPasswordV2, checkVerifyCode } from '@/api/login';
import { REGEXP_PASSWORD } from '@/utils/constants';
import styles from './style.module.scss';
import { StudentInfo } from '@/api/types/home';
import useUser from '@/store/useUser';

interface ChangePwdProps {
  visible: boolean;
  onLogout: (routerParams?: string) => void;
  curStudent: StudentInfo | null;
  onChangeVisible: (visible: boolean) => void;
}

type Account = LoginRes['content'];

type PwdStep = {
  account: Account;
  type: 'phone' | 'email';
  onSubmit: (value: { type?: 'phone' | 'email'; verificationCode?: string; password?: string }) => Promise<void>;
};

const FirstStep = (props: PwdStep) => {
  const { t: loginTranslate } = useTranslation('login');
  const { account, onSubmit } = props;
  const { phone = '', email = '' } = account || {};

  return (
    <>
      <div className={styles.modalTitle}>{loginTranslate('请选择验证方式')}</div>
      <div className={styles.verifyBox}>
        {phone && (
          <div className={styles.boxItem} role="button" onClick={() => onSubmit({ type: 'phone' })}>
            <div className={styles.itemText}>
              <span>{loginTranslate('手机号验证')}</span>
              <span>{phone}</span>
            </div>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="8" fill="#FF5353" />
              <path
                d="M7.33984 10.8008L10.1683 7.97235L7.33984 5.14393"
                stroke="white"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {email && (
          <div className={styles.boxItem} role="button" onClick={() => onSubmit({ type: 'email' })}>
            <div className={styles.itemText}>
              <span>{loginTranslate('邮箱验证')}</span>
              <span>{email}</span>
            </div>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="8" fill="#FF5353" />
              <path
                d="M7.33984 10.8008L10.1683 7.97235L7.33984 5.14393"
                stroke="white"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    </>
  );
};

const SecondStep = (props: PwdStep) => {
  const { t: loginTranslate } = useTranslation('login');
  const { account, type, onSubmit } = props;
  const { phone = '', email = '' } = account || {};
  const [formData, setFormData] = useState<EmailCodeLoginReq>({
    verificationCode: '',
  });
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const handleFormChange = useCallback(
    (key: string, value: string) => {
      setFormData({
        ...formData,
        [key]: value?.trim(),
      });
    },
    [formData],
  );

  const handleSubmit = () => {
    setSubmitLoading(true);
    onSubmit(formData).catch(() => {
      setSubmitLoading(false);
    });
  };

  const submitDisable: boolean = useMemo(() => {
    return Object.values(formData).some((value) => !value);
  }, [formData]);

  return (
    <>
      <div
        className={styles.subTitle}
        dangerouslySetInnerHTML={{
          __html: loginTranslate('账号安全验证提示', { 账号: type === 'email' ? email : phone }),
        }}
      />
      <CodeInput
        onChange={(value: string) => handleFormChange('verificationCode', value)}
        placeholder={loginTranslate('验证码占位')}
        maxLength={6}
        target={type === 'email' ? email : phone}
        className={cls(styles.input, styles.inputCode)}
        type={type === 'email' ? 'EMAIL' : 'MOBILE_PHONE'}
        way="LOGIN"
      />
      <Button
        loading={submitLoading}
        shieldIcon
        className={styles.submit}
        disabled={submitDisable}
        onClick={handleSubmit}
      >
        {loginTranslate('下一步')}
      </Button>
    </>
  );
};

const ThirdStep = (props: PwdStep) => {
  const { onSubmit } = props;
  const [formData, setFormData] = useState<{ password: string; confirmVerificationCode: string }>({
    password: '',
    confirmVerificationCode: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { t } = useTranslation('home');
  const { t: loginTranslate } = useTranslation('login');

  const verifyPassword = useCallback((): boolean => {
    const { password = '' } = formData;
    const result = !REGEXP_PASSWORD.test(password);

    if (result) {
      Toast.warning(t('密码验证提示'));
      setSubmitLoading(false);
    }
    return result;
  }, [formData]);

  const verifyConfirmPassword = useCallback((): boolean => {
    const { password = '', confirmVerificationCode = '' } = formData;
    const result = password !== confirmVerificationCode;

    if (result) {
      Toast.warning(t('两次密码不一致'));
      setSubmitLoading(false);
    }
    return result;
  }, [formData]);

  const passwordNode = (
    <img
      className={styles.icon}
      onClick={() => setShowPassword(!showPassword)}
      src={showPassword ? '/imgs/pc/login-password-show.svg' : '/imgs/pc/login-password-hidden.svg'}
      alt=""
    />
  );

  const confirmPasswordNode = (
    <img
      className={styles.icon}
      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
      src={showConfirmPassword ? '/imgs/pc/login-password-show.svg' : '/imgs/pc/login-password-hidden.svg'}
      alt=""
    />
  );

  const submitDisable: boolean = useMemo(() => {
    return Object.values(formData).some((value) => !value);
  }, [formData]);

  const handleFormChange = useCallback(
    (key: string, value: string) => {
      setFormData({
        ...formData,
        [key]: value?.trim(),
      });
    },
    [formData],
  );

  const handleSubmit = () => {
    if (!verifyPassword() && !verifyConfirmPassword()) {
      setSubmitLoading(true);
      const { password } = formData;
      onSubmit?.({ password }).catch(() => {
        setSubmitLoading(false);
      });
    }
  };

  return (
    <>
      <Input
        onChange={(e) => handleFormChange('password', e.currentTarget.value)}
        placeholder={t('设置新登录密码')}
        maxLength={20}
        suffixCls={styles.password}
        suffix={passwordNode}
        type={showPassword ? 'text' : 'password'}
        className={cls(styles.input, styles.inputCode)}
      />
      <Input
        onChange={(e) => handleFormChange('confirmVerificationCode', e.currentTarget.value)}
        onPressEnter={handleSubmit}
        placeholder={t('请确认新密码')}
        maxLength={20}
        suffixCls={styles.password}
        suffix={confirmPasswordNode}
        type={showConfirmPassword ? 'text' : 'password'}
        className={cls(styles.input, styles.inputCode)}
      />
      <Button loading={submitLoading} className={styles.finished} disabled={submitDisable} onClick={handleSubmit}>
        {loginTranslate('完成')}
      </Button>
    </>
  );
};

const ChangePwd = (props: ChangePwdProps) => {
  const { account: currentAccount } = useUser();
  const { visible, onChangeVisible, onLogout } = props;
  const { t } = useTranslation('home');
  const { t: loginTranslate } = useTranslation('login');
  const [currentStep, setCurrentStep] = useState<'first' | 'second' | 'third'>('first');
  const [currentType, setCurrentType] = useState<'phone' | 'email'>('phone');
  const [verifyCode, setVerifyCode] = useState('');

  const Template = useMemo(() => {
    switch (currentStep) {
      case 'second':
        return SecondStep;
      case 'third':
        return ThirdStep;
      default:
        return FirstStep;
    }
  }, [currentStep]);

  const handleSubmit = async (value: {
    type?: 'phone' | 'email';
    verificationCode?: string;
    password?: string;
  }): Promise<void> => {
    const { phone = '', email = '' } = currentAccount || {};
    const { type, verificationCode = '', password = '' } = value;
    if (currentStep === 'first') {
      setCurrentStep('second');
      setCurrentType(type ?? 'phone');
      return Promise.resolve();
    }
    try {
      const commonParams = {
        target: currentType === 'email' ? email : phone,
        verificationCode,
        verificationType: currentType === 'email' ? 'EMAIL' : 'MOBILE_PHONE',
      };
      if (currentStep === 'second') {
        const result = await checkVerifyCode({
          authenticationWay: 'LOGIN',
          ...(commonParams as any),
        });
        if (result) {
          setCurrentStep('third');
          setVerifyCode(verificationCode);
          return await Promise.resolve();
        }
        Toast.warning(loginTranslate('无效的验证码'));
        return await Promise.reject();
      }
      await forgetPasswordV2({
        ...(commonParams as any),
        password,
        verificationCode: verifyCode,
      });
      onLogout();
      Toast.success(t('密码重置成功'));
      return await Promise.resolve();
    } catch {
      return Promise.reject();
    }
  };

  useEffect(() => {
    if (!visible) {
      setCurrentStep('first');
    }
  }, [visible]);

  return (
    <CustomModal
      className={styles.modal}
      bodyClassName={styles.modalBody}
      closeClassName={styles.modalClose}
      onClose={() => onChangeVisible(false)}
      visible={visible}
      title={t('修改密码')}
    >
      <Template type={currentType} account={currentAccount as Account} onSubmit={handleSubmit} />
    </CustomModal>
  );
};

export default ChangePwd;
