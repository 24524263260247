import { createContext } from 'react';

export interface ContextValue {
  /**
   * 注册回退事件
   */
  registerOnBack: (fn: () => void) => void;
}

export const initValue: ContextValue = {
  registerOnBack: (fn: () => void) => {
    console.log('uninit', fn);
  },
};

const layoutContext = createContext<ContextValue>(initValue);

export default layoutContext;
