import { useTranslation } from 'next-i18next';
import noData from '@public/imgs/no-students.svg';
import { HomeTemplateProps } from '@/api/types/home';
import { NoData } from '@/components';
import Student from '@/pageComponents/Profile/Info/student';
import styles from './style.module.scss';
import { TimezoneRes } from '@/api/common';
import { Options } from '@/components/WKSelect';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
interface IProps {
  onGetStudents(): void;
  timezoneList: TimezoneRes;
  ageRange: Options;
}
export const STUDENT_LIST = 'studentList';
const StudentInfo = (props: Partial<HomeTemplateProps> & IProps) => {
  const { curStudent, students = [], onGetStudents, ageRange, timezoneList } = props;
  const { t: homeTranslation } = useTranslation('home');
  const { t } = useTranslation('profile');
  const { query } = useRouter();
  const { anchor: studentAnchor } = query;

  /**
   *  
   * @param anchorName 
   *  block:表示滚动到锚点的顶部或者底部，start/end
      behavior:表示滚动的效果，auto/instant/smooth(滚动效果)
   */
  const scrollToAnchor = () => {
    let anchorElement = document.getElementById(`${studentAnchor}${curStudent?.uuid}` as string);
    if (anchorElement) {
      anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };
  useEffect(() => {
    scrollToAnchor();
  }, [students]);

  return (
    <div className={styles.studentListContainer}>
      <div className={styles.title}>{t('学生信息')}</div>
      <div className={styles.student}>
        {students?.length ? (
          students?.map((item) => (
            <Student
              anchor={studentAnchor as string}
              ageRange={ageRange}
              onGetStudents={onGetStudents}
              timezoneList={timezoneList}
              key={item.uuid}
              {...item}
              isCurrentStudent={curStudent?.uuid === item.uuid}
              curStudentUUid={curStudent?.uuid}
            />
          ))
        ) : (
          <NoData icon={noData.src} tip={homeTranslation('暂无学生信息')} />
        )}
      </div>
    </div>
  );
};

export default StudentInfo;
