import client from './axios';
import { ContentData } from './types';
import { OssSecretRes } from './types/oss';

type OssSecret = ContentData<OssSecretRes>;
export const getOssSecret = () => {
  return client.get<OssSecret, OssSecret>(`api/file/sts/public`).then((res) => res.content);
};

export default {};
