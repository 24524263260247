/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import React from 'react';
import avatar from '@public/imgs/pc/student-avatar.svg';
import Image from 'next/image';
import cls from 'classnames';

import arrow from '@public/imgs/left-right-arrow.svg';
import { Dropdown } from '@/components';
import { StudentInfo } from '@/api/types/home';

import { log } from '@/api/log';

import styles from './style.module.scss';

import { generateNamePrefix } from '@/utils/helpers';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';
import { traceEvent } from '@wk/wk-gatherer';

interface Props {
  students: StudentInfo[] | null;
  curStudent: StudentInfo | null;
  onSwitch: (data: StudentInfo) => void;
}

const prefixName = generateNamePrefix('home.');

const DEFAULT_STUDENT = 'Student';

const User = (props: Props) => {
  const { curStudent, students, onSwitch } = props;

  const isMultipleStudent = Array.isArray(students) && students.length > 1;
  const { traceEventWithAccount } = useTraceEventWithAccount();

  const onSwitchUser = (student: StudentInfo) => {
    if (student.uuid !== curStudent?.uuid) {
      traceEvent({
        data: {
          _event: 'U_HP_CodeSwitch_CodeClick',
          switchCode: student?.code,
        },
      });
      onSwitch(student);
      log(prefixName`switch_student`);
      // NEED_REFRESH_ROUTES?.includes(pathname) && router.replace(PATHS.curriculum);
    }
  };

  const getUserList = () => {
    if (Array.isArray(students)) {
      return students.map((student) => {
        return (
          <div
            key={student.id}
            role="button"
            className={cls(styles.item, { [styles.active]: student.uuid === curStudent?.uuid })}
            onClick={() => {
              traceEventWithAccount({
                _event: 'U_PC_StudentSwitchingClick',
                student_name: student?.fullName,
              });
              onSwitchUser(student);
            }}
          >
            <img alt="" className={styles.avatar} src={student?.data?.HeadImageUrl || avatar.src} />
            <span className={styles.name}>{student?.fullName}</span>
          </div>
        );
      });
    }
    return [];
  };

  const userInfo = (
    <div className={cls(styles.container, { [styles.pointer]: isMultipleStudent })}>
      <img alt="" className={styles.avatar} src={curStudent?.data?.HeadImageUrl || avatar.src} />
      <section className={styles.userInfo}>
        <span className={styles.name}>{curStudent?.fullName || DEFAULT_STUDENT}</span>
        {isMultipleStudent && (
          <span className={styles.switchUser}>
            <Image src={arrow} />
          </span>
        )}
      </section>
    </div>
  );
  if (isMultipleStudent) {
    return (
      <Dropdown
        optionClassName={styles.wrapper}
        optionChildren={getUserList()}
        mouseEnterTraceClick={() => {
          traceEvent({
            data: {
              _event: 'U_HP_CodeSwitchEnterClick',
            },
          });
        }}
      >
        {userInfo}
      </Dropdown>
    );
  }
  return userInfo;
};

export default User;
