import axios from 'axios';
import { OssRes } from '@/api/types/oss';
import { getOssSecret } from '@/api/oss';

export const uploadFile = async (file: File) => {
  const { formFields, endpoint } = await getOssSecret();

  const formData = new FormData();

  Object.keys(formFields)?.forEach((key) => {
    formData.append(key, formFields?.[key as keyof typeof formFields]);
  });
  formData.append('file', file);
  return axios
    .post<OssRes, OssRes>(`https://${endpoint}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data);
};

export default {};
