import { COMMON_API_HOST as COMMON_API } from '@/utils/env';
import client from './axios';
import { ContentData } from './types';
import { User } from './types/login';
import { ApplyListRes, TimezoneRes, UpdateProfileReq } from './types/profile';
import { getAPIBaseUrl } from '@/utils/url';

const COMMON_API_HOST = getAPIBaseUrl(COMMON_API);

export const getTimezoneList = () => {
  return client
    .get<ContentData<TimezoneRes>, ContentData<TimezoneRes>>(`${COMMON_API_HOST}/public/ref/timezones/ddl`)
    .then((res) => res.content);
};

export const updateProfile = ({ studentId, ...data }: UpdateProfileReq) => {
  return client.post(`api/student/master/v2/update/${studentId}`, data);
};

export const getFamilyMembers = () => {
  return client
    .post<ContentData<User[]>, ContentData<User[]>>(`/api/acc/account/list_family_members`)
    .then((res) => res.content);
};

export const getFamilyCode = () => {
  return client.post<ContentData<string>, ContentData<string>>(`/api/family/invite_code`).then((res) => res.content);
};

export const applyFamilyByCode = (code: string) => {
  return client.post(`/api/family/apply_merge/${code}`);
};

export const getFamilyApplyList = () => {
  return client
    .get<ContentData<ApplyListRes[]>, ContentData<ApplyListRes[]>>(`/api/family/merge_list`)
    .then((res) => res.content);
};

export const applyResult = (applyId: string, result: 'AGREE' | 'REJECT') => {
  return client.post(`/api/family/processing_apply/${applyId}/${result}`);
};

export const applyCount = () => {
  return client.get<ContentData<number>, ContentData<number>>(`/api/family/merge_count`).then((res) => res.content);
};

export const unbindWechat = () => {
  return client.put('api/acc/account/unbind/we_chat');
};

export const bindWechat = (oauthType: string, code: string) => {
  return client.post(`/api/acc/account/bind/we_chat/${oauthType}/${code}`);
};

export const getStudentAgeRange = () => {
  return client.get<ContentData<Array<string>>, ContentData<Array<string>>>('/public/open/age/range');
};

export type RegionMapping = Array<{
  grade: number; //年级code码
  gradeEn: string; //年级英文名
  gradeZh: string; //年级中文名
  year: number;
}>;

export const getGradeMapping = (timezoneValue: string) => {
  return client.get<ContentData<RegionMapping>, ContentData<RegionMapping>>(
    `/public/open/timezones/grade_mapping_one?timezoneValue=${timezoneValue}`,
  );
};

export const updatePrivacyStatus = (status: boolean) => {
  return client.post<{ content: boolean }, { content: boolean }>(`/api/student/setting/updatePrivacyStatus/${status}`);
};
