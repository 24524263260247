import { COMMON_API_HOST as COMMON_API, CRM_API_HOST } from '@/utils/env';
import client from './axios';
import { DataType, ContentData } from './types';
import {
  AvailableDateReq,
  AvailableDateRes,
  CountryRes,
  SubmitReserveRes,
  SubmitReserveReq,
  CluesDataReq,
  CluesDataRes,
  ReportEventReq,
  ReportEventRes,
  ConstantsRes,
  ContactReq,
  RetentionInfoRes,
  GetTimeZoneListRes,
} from './types/reserve';
import { getAPIBaseUrl } from '@/utils/url';

const COMMON_API_HOST = getAPIBaseUrl(COMMON_API);

type SUBJECT_TYPE = 'CHINESE' | 'MATH' | 'ENGLISH';

export const getCountry = (time: string) => {
  return client
    .post<DataType<CountryRes>, DataType<CountryRes>>(`${COMMON_API_HOST}/public/reserve/getCountry`, { time })
    .then((res) => res.data);
};

export const getAvailableDate = (data: AvailableDateReq) => {
  return client
    .post<ContentData<AvailableDateRes[]>, ContentData<AvailableDateRes[]>>(`/api/reserve/available_time`, data)
    .then((res) => res.content);
};

export const getAvailableDatePublic = (data: AvailableDateReq) => {
  return client.post<AvailableDateRes[], AvailableDateRes[]>(
    `${COMMON_API_HOST}/public/reserve/v2/available_time`,
    data,
  );
};

export const submitReserve = (data: SubmitReserveReq) => {
  return client
    .post<SubmitReserveRes, SubmitReserveRes>(`${COMMON_API_HOST}/public/reserve/v2/reserve`, data)
    .then((res) => res);
};

// 线索trackId反查数据
export const getCluesData = (data: CluesDataReq) => {
  return client.get<CluesDataRes, CluesDataRes>(`${CRM_API_HOST}/open/track/${data.trackId}`).then((res) => res);
};

// 上报埋点数据给后端
export const reportEvent = (data: ReportEventReq) => {
  return client.post<ReportEventRes, ReportEventRes>(`${CRM_API_HOST}/open/track/link_log`, data).then((res) => res);
};

export const reserve = (data: SubmitReserveReq) => {
  return client.post<SubmitReserveRes, SubmitReserveRes>(`/api/reserve`, data).then((res) => res);
};

export const reserveConstants = () => {
  return client.get<ContentData<ConstantsRes>, ContentData<ConstantsRes>>('/api/reserve/constant');
};

export const finishPhone = (data: ContactReq) => {
  return client
    .post<ContentData<String>, ContentData<String>>(`/api/reserve/complete/contact`, data)
    .then((res) => res.content);
};

export const checkReserveStatus = (type: SUBJECT_TYPE) => {
  return client
    .get<ContentData<String>, ContentData<String>>(`/api/reserve/track/info/${type}`)
    .then((res) => res.content);
};

export const getCacheReason = () => {
  return client
    .get<ContentData<string[]>, ContentData<string[]>>('api/student/schedule/cancelSchedule/reason')
    .then((res) => res.content);
};

export const sendReasons = (data: { courseScheduleId: string; reasons: string[] }) => {
  return client.post('api/student/schedule/cancelSchedule', data);
};

export const getReserveInfo = (data: { subject: SUBJECT_TYPE; courseScheduleId?: string }) => {
  return client
    .post<ContentData<any>, ContentData<any>>('/api/reserve/new_subject_info', data)
    .then((res) => res.content);
};

export const checkReserve = (subject: SUBJECT_TYPE) => {
  return client
    .get<
      ContentData<{ reserveContinueStatus: 'RESERVE' }>,
      ContentData<{ reserveContinueStatus: 'RESERVE' }>
    >(`/api/reserve/check_reserve/${subject}`)
    .then((res) => res.content);
};

export const getReserveDetail = (data: { subject: string; courseScheduleId?: string }) => {
  return client.post<ContentData<any>, ContentData<any>>('/api/reserve/trial_info', data).then((res) => res.content);
};

export const getReserveEmail = () => {
  return client
    .get<ContentData<boolean>, ContentData<boolean>>('/api/reserve/is_input_email')
    .then((res) => res.content);
};

export const getReserveEmailV2 = () => {
  return client
    .get<ContentData<boolean>, ContentData<boolean>>('/api/reserve/is_input_email_v2')
    .then((res) => res.content);
};

export const getReserveMobile = () => {
  return client
    .get<ContentData<boolean>, ContentData<boolean>>('/api/reserve/is_input_mobile')
    .then((res) => res.content);
};

export const reserveSubject = (data: SubmitReserveReq) => {
  return client.post('/api/reserve/new_subject_info/save', data);
};

// 获取当前用户  手机号和年级
export const getRetentionInfo = () => {
  return client
    .get<ContentData<RetentionInfoRes>, ContentData<RetentionInfoRes>>('/api/acc/track/phone')
    .then((res) => res.content);
};

export const getTimeZoneList = () => {
  return client
    .get<GetTimeZoneListRes, GetTimeZoneListRes>('public/open/timezones/ddl/group')
    .then((res) => res.content);
};
