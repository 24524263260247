import styles from './style.module.scss';
import React, { MouseEvent, ReactNode } from 'react';
import cls from 'classnames';
import Image from 'next/image';
import loadingIcon from '@public/imgs/loading.svg';

export interface WKButtonProps {
  children?: ReactNode;
  type?: 'primary' | 'warn' | 'err';
  onClick?: (e: MouseEvent<HTMLButtonElement | MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
}

export const WKButton = (props: WKButtonProps) => {
  const { children, type = 'default', onClick, loading, disabled } = props;
  return (
    <button
      className={cls(styles.wkButton, styles[type], disabled && styles.disabled)}
      onClick={(e) => {
        if (disabled || loading) return;
        onClick?.(e);
      }}
    >
      {loading && (
        <div className={styles.loading}>
          <Image width={24} src={loadingIcon} alt={'loading'} />
        </div>
      )}
      {children}
    </button>
  );
};
