/**
 * 主要管理消息展示逻辑
 */
import { GetUnReadMessageNumRes } from '@/api/types/message';
import create from 'zustand';
import { combine } from 'zustand/middleware';

export type News =
  | {
      show?: boolean;
    }
  | undefined;

export interface MenuNews {
  home?: News;
  lesson?: News;
  leave?: News;
  lessonTask?: News;
  orderCenter?: News;
}

export interface NewsContextType {
  menuNews: MenuNews;
  unReadMessageInfo: GetUnReadMessageNumRes['content'] | any;
}

const initialState: NewsContextType = {
  menuNews: {
    home: undefined,
    lesson: undefined,
    leave: undefined,
    lessonTask: undefined,
  },
  unReadMessageInfo: {},
};

interface UserActionType {
  updateNews(news: MenuNews): void;
  setUnReadMessageInfo(messageInfo: GetUnReadMessageNumRes['content']): void;
}

const useNews = create(
  combine<NewsContextType, UserActionType>(initialState, (set) => ({
    updateNews: (news: MenuNews) => {
      set(() => ({ menuNews: news }));
    },
    setUnReadMessageInfo: (messageInfo: GetUnReadMessageNumRes['content']) => {
      set(() => ({ unReadMessageInfo: messageInfo }));
    },
  })),
);

export default useNews;
