import styles from './style.module.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cls from 'classnames';
import { SearchIcon } from '@/components/WKSelect/SearchIcon';
import { DownIcon } from '@/components/WKSelect/DownIcon';

export type Option = { label: string; value: string | number };

export type Options = Array<Option>;

export interface WKSelectProps {
  search?: boolean;
  value?: string | number;
  defaultValue?: string | number;
  options?: Options;
  onChange?: (option: Option['value']) => void;
  placeholder?: string;
  defaultOpen?: boolean;
}

const WKSelect = (props: WKSelectProps) => {
  const { defaultValue, search, options = [], onChange, placeholder = 'Please select', defaultOpen = false } = props;
  const [open, setOpen] = useState(defaultOpen);
  const [value, setValue] = useState((props.value || props.defaultValue) ?? '');
  const [searchValue, setSearchValue] = useState('');

  const memorizedLabel = useMemo(() => {
    if (value.toString()) {
      return options.find((item) => item.value === value)?.label || placeholder;
    }
    if (defaultValue?.toString() && !value.toString()) {
      return options.find((item) => item.value === defaultValue)?.label || placeholder;
    }
    return placeholder;
  }, [defaultValue, options, placeholder, value]);

  const memorizedFilterDropdownList = useMemo(() => {
    if (searchValue) {
      return options.filter((item) => item.label.includes(searchValue));
    }
    return options;
  }, [options, searchValue]);

  const ref = useRef(null);

  const memorizedToggleOpen = useCallback(
    /*
     * todo:
     * @types/react 17: type err -> use any type
     * */
    (e: any) => {
      document.body.click();
      e.stopPropagation();
      setOpen(!open);
    },
    [open],
  );

  const memorizedCloseDropdown = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    !defaultOpen && document.body.addEventListener('click', memorizedCloseDropdown);
    return () => document.body.removeEventListener('click', memorizedCloseDropdown);
  }, [memorizedCloseDropdown]);

  useEffect(() => {
    if (!open) setSearchValue('');
  }, [open]);

  return (
    <div className={styles.wkSelect} ref={ref}>
      <div className={styles.wkSelectLabel} onClick={memorizedToggleOpen}>
        <p className={cls(!value.toString() && !defaultValue?.toString() && styles.noValue)}>{memorizedLabel}</p>
        <DownIcon style={{ position: 'absolute', right: '10px', top: '13px' }} />
      </div>
      <div className={cls(styles.wkSelectDropdown, open && styles.open)}>
        {search && (
          <div className={styles.wkSelectDropdownSearch} onClick={(e) => e.stopPropagation()}>
            <input
              value={searchValue}
              onChange={(e) => {
                const v = e.target.value;
                setSearchValue(v);
              }}
              type="text"
              className={styles.wkSelectDropdownSearchInput}
            />
            <SearchIcon />
          </div>
        )}
        {memorizedFilterDropdownList.length ? (
          <ul className={styles.wkSelectDropdownList}>
            {memorizedFilterDropdownList.map((item, index) => {
              return (
                <li
                  className={cls(value === item.value && styles.act)}
                  key={item.value + item.label + index.toString()}
                  onClick={() => {
                    setValue(item.value);
                    onChange && onChange(item.value);
                  }}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default WKSelect;
