import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import cls from 'classnames';
import studyIcon from '@public/imgs/pc/study-icon.svg';
import studyActiveIcon from '@public/imgs/pc/study-active-icon.svg';
import leaveRecordIcon from '@public/imgs/pc/leave-record-icon.svg';
import leaveRecordActiveIcon from '@public/imgs/pc/leave-record-active-icon.svg';
import aiIcon from '@public/imgs/pc/ai-menu-icon.svg';
import aiActiveIcon from '@public/imgs/pc/ai-menu-active-icon.svg';
import shareIcon from '@public/imgs/pc/menu-share-icon.svg';
import shareActiveIcon from '@public/imgs/pc/menu-share-active-icon.svg';
import menuHomeIcon from '@public/imgs/pc/home-menu-home-new.svg';
import menuHomeActiveIcon from '@public/imgs/pc/home-menu-home-active-new.svg';
import learningTaskIcon from '@public/imgs/pc/learning-task-icon.svg';
import learningTaskActiveIcon from '@public/imgs/pc/learning-task-active-icon.svg';
import orderCenterIcon from '@public/imgs/pc/order-center-icon.svg';
import orderCenterActiveIcon from '@public/imgs/pc/order-center-active-icon.svg';
import PATHS from '@/utils/paths';
import { StudentInfo, HomeTemplateProps, SubscriptionEsRes } from '@/api/types/home';
import { removeTokenInfo } from '@/utils/helpers';
import { Loading } from '@/components';
import ProcessingLoading from '@/components/NewLoading';
import News from '@/components/News';
import { hasAiLesson } from '@/api/ai';
import { checkShareAccess } from '@/api/home';
import cacheStorage from '@/utils/cacheStorage';
import { USER_ID } from '@/utils/constants';
import { User } from '@/api/types/login';
import HomeMenu from '../Menu';
import HomeHeader from '../Header';
import styles from './style.module.scss';
import { isTourist } from '@/utils/utils';

// import Chat from '../Chat';

const HOME_PAGES = [
  {
    icon: menuHomeIcon,
    activeIcon: menuHomeActiveIcon,
    title: '首页', // i18n 的 key 值
    path: PATHS.home,
    activePath: PATHS.home,
    strict: true, // 严格匹配
    id: 'home',
  },
  {
    icon: studyIcon,
    activeIcon: studyActiveIcon,
    title: '悟空课堂', // i18n 的 key 值
    path: PATHS.lesson,
    activePath: PATHS.lesson,
    strict: true,
    id: 'lesson',
  },
  {
    icon: leaveRecordIcon,
    activeIcon: leaveRecordActiveIcon,
    title: '在线请假', // i18n 的 key 值
    path: PATHS.leave,
    activePath: PATHS.leave,
    id: 'leave',
  },
  {
    icon: learningTaskIcon,
    activeIcon: learningTaskActiveIcon,
    title: '学习任务-menu', // i18n 的 key 值
    path: PATHS.lessonTask,
    activePath: PATHS.lessonTask,
    strict: true,
    id: 'lessonTask',
    renderNews: () => <News type="lessonTask" />,
  },
  {
    icon: orderCenterIcon,
    activeIcon: orderCenterActiveIcon,
    title: '订单中心', // i18n 的 key 值
    path: PATHS.orderCenter,
    activePath: PATHS.orderCenter,
    strict: true,
    id: 'orderCenter',
    renderNews: () => <News type="orderCenter" />,
  },
];

const DYNAMIC_HOME_PAGE = {
  ai: {
    icon: aiIcon,
    activeIcon: aiActiveIcon,
    title: 'AI绘本馆', // i18n 的 key 值
    path: PATHS.aiList,
    activePath: PATHS.ai,
    id: 'ai',
  },
  share: {
    icon: shareIcon,
    activeIcon: shareActiveIcon,
    title: '推荐赠礼', // i18n 的 key 值
    path: PATHS.share,
    activePath: PATHS.share,
    id: 'share',
  },
};

interface HomeWrapperProps {
  enableCenterMode?: boolean;
  students: StudentInfo[] | null;
  curStudent: StudentInfo | null;
  hiddenMenu?: boolean;
  showStudentCenter?: boolean;
  setCurStudent: (data: StudentInfo) => void;
  clearStudents: () => void;
  onGetStudents: () => void;
  component?: React.FC<HomeTemplateProps>;
  children: React.ReactElement;
  account: User | null;
  setAccount: (user: User | null) => void;
  showReserveModal?: boolean;
  showHeaderBack?: boolean;
  canSwitchStudent?: boolean;
  isProcessingLoading?: boolean;
  hideHeader?: boolean;
  handleCustomBack?: ((_: any) => void) | null | undefined;
  showLanguageSwitching?: boolean;
  showTools?: boolean;
  stuScheduleContent?: SubscriptionEsRes | null;
}

const HomeWrapper: React.FC<HomeWrapperProps> = ({
  children,
  enableCenterMode = false,
  hiddenMenu = false,
  showStudentCenter = false,
  showReserveModal,
  showHeaderBack = false,
  handleCustomBack,
  canSwitchStudent,
  isProcessingLoading = false,
  hideHeader = false,
  showLanguageSwitching = true,
  showTools = true,
  ...resetProps
}) => {
  const [loading, setLoading] = useState(true);
  const [canBack, setCanBack] = useState(true);
  const { curStudent, students, setCurStudent, stuScheduleContent } = resetProps;
  const router = useRouter();
  const [menus, setMenus] = useState<
    {
      icon: StaticImageData;
      activeIcon: StaticImageData;
      title: string;
      path: string;
      activePath: string;
      id: string;
    }[]
  >([]);

  const memorizedHandleCanBack = useCallback((b) => {
    setCanBack(b);
  }, []);

  const logout = (routerParams?: string) => {
    setLoading(true);
    window?.Intercom?.('shutdown');
    removeTokenInfo(routerParams);
  };

  useEffect(() => {
    (async () => {
      if (isTourist()) {
        // fix: 游客模式下，点击首页，丢失 sign
        const menu = { ...HOME_PAGES[0] };
        menu.path = window.location.href;
        setMenus([menu]);
        return;
      }
      const userId = cacheStorage.getItem(USER_ID);
      if (curStudent) {
        cacheStorage.setItem(USER_ID, curStudent?.uuid);
        const { content } = await hasAiLesson();
        const checkRes = await checkShareAccess();
        setMenus([HOME_PAGES[0], HOME_PAGES[1], HOME_PAGES[3]]);

        if (content) {
          setMenus((prev) => {
            return [...prev, DYNAMIC_HOME_PAGE.ai];
          });
        }

        if (checkRes?.success) {
          setMenus((prev) => {
            return [...prev, DYNAMIC_HOME_PAGE.share];
          });
        }

        setMenus((prev) => {
          // todo: 暂时不展示订单中心 HOME_PAGES[4]
          return [...prev];
        });

        if (content && curStudent.uuid !== userId && router?.asPath.startsWith(PATHS.aiLesson)) {
          router.replace(PATHS.aiList);
          return;
        }

        if (router?.asPath.startsWith(PATHS.ai)) {
          // 如果学员没有AI绘本，跳转到首页
          if (!content) {
            router.replace(PATHS.home);
          }
        }
      } else {
        setMenus([HOME_PAGES[0]]);
      }
    })();
  }, [curStudent, router]);

  const memorizedHandleLoadingChange = useCallback((b) => {
    setLoading(b);
  }, []);

  useEffect(() => {
    document.body.scrollTo({ left: 0, top: 0 });
  }, [router.pathname]);

  const getRenderWrapper = () => {
    return (
      <div className={styles.homeWrapper}>
        {!hiddenMenu && <HomeMenu {...resetProps} menuConfigs={menus} />}
        <div className={cls(styles.container, hiddenMenu && styles.containerFull)}>
          {!hideHeader && (
            <HomeHeader
              canBack={canBack}
              curStudent={curStudent}
              students={students}
              setCurStudent={setCurStudent}
              onLogout={logout}
              timezone={curStudent?.timezone}
              timezoneStd={curStudent?.timezoneStd}
              showStudentCenter={showStudentCenter}
              showReserveModal={showReserveModal}
              showHeaderBack={showHeaderBack}
              handleCustomBack={handleCustomBack}
              canSwitchStudent={canSwitchStudent}
              showLanguageSwitching={showLanguageSwitching}
              showTools={showTools}
              stuScheduleContent={stuScheduleContent}
            />
          )}

          <div className={cls(styles.wrapper, 'layout-wrapper')} id="page-home-layout-wrapper-container">
            {isProcessingLoading ? (
              <ProcessingLoading loading={loading} />
            ) : (
              <Loading className={styles.loading} loading={loading} />
            )}
            {React.cloneElement(children, {
              ...resetProps,
              onLoading: memorizedHandleLoadingChange,
              isWrapperLoading: loading,
              onCanBackChange: memorizedHandleCanBack,
            })}
          </div>
        </div>
        {/* <Chat attendee={{ name: curStudent?.fullName, age: curStudent?.currentAge, level: 'level2-A-09' }} /> */}
      </div>
    );
  };

  const getCenterModeRenderWrapper = () => {
    return (
      <div className={cls(styles.homeWrapper, styles.centerMode)}>
        {!hideHeader && (
          <HomeHeader
            canBack={canBack}
            curStudent={curStudent}
            students={students}
            setCurStudent={setCurStudent}
            onLogout={logout}
            timezone={curStudent?.timezone}
            timezoneStd={curStudent?.timezoneStd}
            showStudentCenter={showStudentCenter}
            showReserveModal={showReserveModal}
            showHeaderBack={showHeaderBack}
            handleCustomBack={handleCustomBack}
            canSwitchStudent={canSwitchStudent}
            showLanguageSwitching={showLanguageSwitching}
            showTools={showTools}
            stuScheduleContent={stuScheduleContent}
          />
        )}

        <div className={cls(styles.container, styles.centerMode, hiddenMenu && styles.containerFull)}>
          <section className={styles.centerModeContainer}>
            {!hiddenMenu && <HomeMenu {...resetProps} menuConfigs={menus} />}

            <div className={cls(styles.wrapper, styles.centerMode)}>
              {isProcessingLoading ? (
                <ProcessingLoading loading={loading} />
              ) : (
                <Loading className={styles.loading} loading={loading} />
              )}
              {React.cloneElement(children, {
                ...resetProps,
                onLoading: memorizedHandleLoadingChange,
                isWrapperLoading: loading,
                onCanBackChange: memorizedHandleCanBack,
              })}
            </div>
          </section>
        </div>
        {/* <Chat attendee={{ name: curStudent?.fullName, age: curStudent?.currentAge, level: 'level2-A-09' }} /> */}
      </div>
    );
  };

  return enableCenterMode ? getCenterModeRenderWrapper() : getRenderWrapper();
};

export default HomeWrapper;
