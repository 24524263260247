import { AxiosRequestConfig } from 'axios';
import client from './axios';

interface Log {
  event: string;
  data: Record<string, any>;
}

export const uploadLog = (data: Log, config?: AxiosRequestConfig) => {
  return client.post<void, void>(`public/log/behavior`, data, config);
};

export const log = (event: string, data?: Record<string, any>) => {
  return uploadLog({ event: `student-center.${event}`, data: data || {} });
};
