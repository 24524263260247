import { SVGProps } from 'react';

export const CameraIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_7_9152)">
        <circle cx="11.9984" cy="11.85" r="4.28182" stroke="white" strokeWidth="1.63636" />
        <circle cx="19.3508" cy="7.65083" r="1.05" fill="white" />
        <path
          d="M1.5 5.82881C1.5 5.00039 2.17157 4.32882 3 4.32882H5.85209C6.12797 4.32882 6.38158 4.17736 6.5124 3.93446L7.2876 2.49518C7.41842 2.25229 7.67203 2.10083 7.94791 2.10083H16.0521C16.328 2.10083 16.5816 2.25229 16.7124 2.49518L17.4876 3.93446C17.6184 4.17735 17.872 4.32882 18.1479 4.32882H21C21.8284 4.32882 22.5 5.00039 22.5 5.82882V18.9002C22.5 19.7287 21.8284 20.4002 21 20.4002H3C2.17157 20.4002 1.5 19.7287 1.5 18.9002V5.82881Z"
          stroke="white"
          strokeWidth="1.63636"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_9152">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
