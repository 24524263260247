import { SVGProps } from 'react';

export const SearchIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx="9.54099"
        cy="9.09998"
        r="5.02727"
        transform="rotate(-45 9.54099 9.09998)"
        stroke="#484C70"
        strokeWidth="1.4"
      />
      <path
        d="M12.5195 13.237L13.7128 12.0438C14.6165 12.9474 15.1993 13.5303 15.9798 14.3108C16.3093 14.6403 16.3094 15.1747 15.9799 15.5042C15.6504 15.8337 15.1162 15.8337 14.7867 15.5042L12.5195 13.237Z"
        fill="#484C70"
      />
    </svg>
  );
};
