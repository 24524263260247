import React from 'react';
import useNews, { MenuNews } from '@/store/useNews';
import styles from './style.module.scss';

type IProps = {
  type: keyof MenuNews;
};

export default (props: IProps) => {
  const { type } = props;
  const { menuNews } = useNews();
  if (menuNews?.[type]?.show) {
    return <div className={styles.news} />;
  }
  return null;
};
