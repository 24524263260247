import { HTMLAttributes } from 'react';

export const CopyIcon = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div style={{ display: 'flex' }} {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7_8849)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.0013 1.99996C4.8172 1.99996 4.66797 2.14919 4.66797 2.33329V3.33329H11.0013C11.9218 3.33329 12.668 4.07948 12.668 4.99996V11.3333H13.668C13.8521 11.3333 14.0013 11.1841 14.0013 11V2.33329C14.0013 2.14919 13.8521 1.99996 13.668 1.99996H5.0013ZM12.668 12.6666H13.668C14.5885 12.6666 15.3346 11.9204 15.3346 11V2.33329C15.3346 1.41283 14.5885 0.666626 13.668 0.666626H5.0013C4.0808 0.666626 3.33464 1.41283 3.33464 2.33329V3.33329H2.33464C1.41416 3.33329 0.667969 4.07948 0.667969 4.99996V13.6666C0.667969 14.5871 1.41416 15.3333 2.33464 15.3333H11.0013C11.9218 15.3333 12.668 14.5871 12.668 13.6666V12.6666ZM2.33464 4.66663C2.15054 4.66663 2.0013 4.81586 2.0013 4.99996V13.6666C2.0013 13.8507 2.15054 14 2.33464 14H11.0013C11.1854 14 11.3346 13.8507 11.3346 13.6666V4.99996C11.3346 4.81586 11.1854 4.66663 11.0013 4.66663H2.33464Z"
            fill="#C6C7D2"
          />
        </g>
        <defs>
          <clipPath id="clip0_7_8849">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
