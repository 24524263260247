import client from './axios';
import { GetMessageListRes, GetUnReadMessageNumRes } from './types/message';

/**
 * 获取未读消息数量
 */
export const getUnReadMessageNum = () => {
  return client
    .get<GetUnReadMessageNumRes, GetUnReadMessageNumRes>('/api/student/station_message/unread')
    .then((res) => res.content);
};

/**
 * 获取课程提醒消息列表
 *
 * @param {number} currentPage
 * @return {*}
 */
export const getCourseMessageList = (currentPage: number) => {
  return client.post<GetMessageListRes, GetMessageListRes>(`/api/student/station_message/${currentPage}`, {});
};

/**
 * 获取服务通知消息列表
 *
 * @param {number} currentPage
 * @return {*}
 */
export const getServiceMessageList = (currentPage: number) => {
  return client.post<GetMessageListRes, GetMessageListRes>(
    `/api/student/station_message/search/system/${currentPage}`,
    {},
  );
};

/**
 * 查看站内消息，通知后端该消息已查看
 *
 * @param {string} uuid
 * @return {*}
 */
export const readMessage = (uuid: string) => {
  return client.post<any, any>(`/api/student/station_message/view/${uuid}`, {});
};
