import { SVGProps } from 'react';

export const DownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_7_9486" maskUnits="userSpaceOnUse" x="1" y="1" width="10" height="10">
        <rect x="11" y="1" width="10" height="10" transform="rotate(90 11 1)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7_9486)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.30913 4.55806C2.55321 4.31398 2.94894 4.31398 3.19301 4.55806L5.93305 7.2981L8.67309 4.55806C8.91717 4.31398 9.3129 4.31398 9.55697 4.55806C9.80105 4.80214 9.80105 5.19786 9.55697 5.44194L6.37499 8.62392C6.13092 8.868 5.73519 8.868 5.49111 8.62392L2.30913 5.44194C2.06505 5.19786 2.06505 4.80214 2.30913 4.55806Z"
          fill="#8D8FA6"
        />
      </g>
    </svg>
  );
};
