import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { useTranslation } from 'next-i18next';
import useAuth from '@/utils/hooks/useAuth';
import HomeWrapper from '@/pageComponents/Home/Wrapper';
import { getAccountInfo, getStudentList, getSubscriptionEsStatus } from '@/api/home';
import { StudentInfo, SubscriptionEsRes } from '@/api/types/home';
import useUser from '@/store/useUser';
import { switchStudent } from '@/api/login';
import { checkTokenInfo, setTokenInfo } from '@/utils/helpers';
import { setSentryContext, setSentryUser, USER_TIMEZONE_CONTEXT } from '@/utils/sentryUtil';
import cacheStorage from '@/utils/cacheStorage';
import { CURRENT_ACCOUNT_ID, CURRENT_STUDENT_CODE, REFRESH_TOKEN, TOKEN } from '@/utils/constants';
import { updateIntercomInfo } from '@/utils/utils';
import layoutContext, { ContextValue } from './context';
import { useRouter } from 'next/router';

const USER_TOKEN = 'USER_TOKEN';

// eslint-disable-next-line react/require-default-props
const HomeLayout = ({
  children,
  enableCenterMode,
  hiddenMenu = false,
  showStudentCenter = false,
  showReserveModal,
  showHeaderBack = false,
  handleCustomBack = null,
  hideHeader = false,
  canSwitchStudent,
  isProcessingLoading,
  showLanguageSwitching = true,
  showTools = true,
}: {
  children: React.ReactElement;
  enableCenterMode?: boolean;
  hiddenMenu?: boolean;
  showStudentCenter?: boolean;
  showReserveModal?: boolean;
  showHeaderBack?: boolean;
  canSwitchStudent?: boolean;
  isProcessingLoading?: boolean;
  hideHeader?: boolean;
  handleCustomBack?: ((_: any) => void) | null | undefined;
  showLanguageSwitching?: boolean;
  showTools?: boolean;
}) => {
  useAuth();
  const { i18n } = useTranslation();
  const router = useRouter();
  const [stuScheduleContent, setStuScheduleContent] = useState<SubscriptionEsRes>();
  // const { onBack } = useBookClassPrivateStore();

  // hack逻辑 当前环境如果是英文的话 不显示 ...同学
  const {
    students,
    enStudents,
    curStudent,
    account,
    setAccount,
    setStudents,
    setCurStudent,
    clearStudents,
    updateIpConfig,
  } = useUser((store) => ({
    curStudent: store.curStudent,
    students: store.students,
    account: store.account,
    enStudents: cloneDeep(store.students)?.map((student) => {
      const names = student.fullName.split(' ').reverse();
      if (names?.[0] === '同学') {
        names?.shift();
      }
      names?.reverse();
      return { ...student, fullName: names.join(' ') };
    }),
    setAccount: store.updateAccount,
    setStudents: store.updateStudents,
    setCurStudent: store.updateCurStudent,
    clearStudents: store.clear,
    updateIpConfig: store.updateIpConfig,
  }));

  const fetchSubscriptionData = async () => {
    const data = await getSubscriptionEsStatus();
    setStuScheduleContent({
      display: data?.display,
      stuScheduleStatus: data?.stuScheduleStatus as string,
    });
  };

  useEffect(() => {
    if (curStudent) {
      const { uuid, fullName, timezone, timezoneStd, classInId } = curStudent || {};
      updateIntercomInfo(curStudent);
      updateIpConfig();
      setSentryUser({
        id: uuid,
        username: fullName,
      });
      setSentryContext(USER_TIMEZONE_CONTEXT, {
        timezone,
        timezoneStd,
        classInId,
      });
      setSentryContext(USER_TOKEN, {
        token: cacheStorage.getItem(TOKEN),
        refreshToken: cacheStorage.getItem(REFRESH_TOKEN),
      });
    }
  }, [curStudent]);

  useEffect(() => {
    if (curStudent) {
      fetchSubscriptionData();
    }
  }, [curStudent, router.pathname]);
  const getStudents = useCallback(
    async (forceRefresh = false) => {
      if (forceRefresh || !curStudent) {
        const results = await getStudentList();
        window.sessionStorage.setItem(CURRENT_STUDENT_CODE, results?.[0]?.code);
        setStudents(results);
      }
    },
    [curStudent, setStudents],
  );

  const getAccount = useCallback(async () => {
    const result = await getAccountInfo();
    window.sessionStorage.setItem(CURRENT_ACCOUNT_ID, result?.uuid);
    setAccount(result);
  }, [setAccount]);

  const handleSetStudent = useCallback(
    async (student: StudentInfo) => {
      const { content } = await switchStudent(student.uuid);
      const { refreshToken, token } = content || {};
      setTokenInfo(token, refreshToken);
      setCurStudent(student);
    },
    [setCurStudent],
  );

  useEffect(() => {
    // 登陆模式下调用接口
    if (checkTokenInfo()) {
      /*
       * syntax supplement
       * */
      getStudents().then();
      getAccount().then();
    }
  }, [getAccount, getStudents]);

  const compCurStudent = useMemo(() => {
    if (i18n.language === 'en' && curStudent) {
      curStudent.fullName = enStudents?.find((item) => item.uuid === curStudent?.uuid)?.fullName || '';
    }
    if (i18n.language === 'zh' && curStudent) {
      curStudent.fullName = students?.find((item) => item.uuid === curStudent?.uuid)?.fullName || '';
    }
    return curStudent;
  }, [curStudent, enStudents, i18n.language, students]);

  const onBackRef = useRef<() => void>();
  const contextValue = useMemo<ContextValue>(
    () => ({
      registerOnBack: (cb: () => void) => {
        onBackRef.current = cb;
      },
    }),
    [],
  );

  const handleBack = (p: any) => {
    if (handleCustomBack) {
      handleCustomBack(p);
    } else if (onBackRef.current) {
      onBackRef.current();
    } else {
      router.back();
    }
  };
  return (
    <>
      <layoutContext.Provider value={contextValue}>
        <HomeWrapper
          enableCenterMode={enableCenterMode}
          students={i18n.language === 'en' ? enStudents || null : students}
          onGetStudents={() => getStudents(true)}
          curStudent={compCurStudent}
          setCurStudent={handleSetStudent}
          clearStudents={clearStudents}
          hiddenMenu={hiddenMenu}
          account={account}
          hideHeader={hideHeader}
          setAccount={setAccount}
          showStudentCenter={showStudentCenter}
          showReserveModal={showReserveModal}
          showHeaderBack={showHeaderBack}
          handleCustomBack={handleBack}
          canSwitchStudent={canSwitchStudent}
          isProcessingLoading={isProcessingLoading}
          showLanguageSwitching={showLanguageSwitching}
          showTools={showTools}
          stuScheduleContent={stuScheduleContent}
        >
          {children}
        </HomeWrapper>
      </layoutContext.Provider>
    </>
  );
};

export default HomeLayout;
