import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';
import Cropper from 'react-cropper';
import avatar from '@public/imgs/pc/student-avatar.svg';
import edit from '@public/imgs/profile-edit.svg';
import { StudentInfo } from '@/api/types/home';
import { TimezoneRes, UpdateProfileReq } from '@/api/types/profile';
import { uploadFile } from '@/utils/oss';
import { Button, CustomModal, Input, Toast } from '@/components';
import 'cropperjs/dist/cropper.css';
import { getGradeMapping, updateProfile } from '@/api/profile';
import { generateNamePrefix } from '@/utils/helpers';
import styles from './style.module.scss';
import { log } from '@/api/log';
import Image from 'next/image';
import copy from 'copy-to-clipboard';
import WKSelect, { Options } from '@/components/WKSelect';
import { WKButton } from '@/components/WKButton';
import { CameraIcon } from '@/components/AppointmentModalClockIcon/CameraIcon';
import { CopyIcon } from '@/components/AppointmentModalClockIcon/CopyIcon';
import { STUDENT_LIST } from '@/pageComponents/Profile/Info/index';

export interface ProfileInfoProps extends StudentInfo {
  isCurrentStudent: boolean;
  timezoneList: TimezoneRes;

  onGetStudents(): void;

  ageRange: Options;
  anchor?: string | null;
  curStudentUUid?: string;
}

const MAX_FILE_SIZE = 8 * 1024 * 1024;
const prefixName = generateNamePrefix('profile.');

const ProfileInfo = (props: ProfileInfoProps) => {
  const { t, i18n } = useTranslation('profile');
  const { t: commonT } = useTranslation('common');

  const {
    data,
    fullName,
    timezoneStd,
    code,
    isCurrentStudent,
    timezoneList,
    uuid,
    onGetStudents,
    englishName,
    currentGrade,
    currentAge,
    displayGradeEn,
    displayGradeZh,
    anchor,
    curStudentUUid,
  } = props;
  const [editMode, setEditMode] = useState<Boolean>(anchor === STUDENT_LIST && isCurrentStudent);
  const [selectZone, setSelectZone] = useState(timezoneStd);
  const fileRef = useRef<HTMLInputElement>(null);
  const [cropper, setCropper] = useState<Cropper>();
  const previewRef = useRef<HTMLDivElement>(null);
  const [modalVis, setModalVis] = useState<boolean>(false);
  const [tempUrl, setTempUrl] = useState<string>('');
  const [modalSubmitLoading, setModalSubmitLoading] = useState<boolean>(false);
  const [avatarUrl, setAvatarUrl] = useState<string>('');
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [englishNameState, setEnglishName] = useState(englishName);
  const [fullNameState, setFullName] = useState(fullName);
  const [currentAgeState, setCurrentAge] = useState(currentAge);
  const [currentGradeState, setCurrentGrade] = useState(currentGrade);
  const [regionMapping, setRegionMapping] = useState<Options>([]);

  const memorizedHandleDisplayGradeChange = useCallback((n) => {
    setCurrentGrade(n);
  }, []);

  const memorizedHandleTimezoneChange = useCallback((s) => {
    setSelectZone(s);
  }, []);

  const onEnglishNameChange = useCallback((e) => {
    const v = e.target.value;
    setEnglishName(v);
  }, []);

  const onFullNameChange = useCallback((e) => {
    const v = e.target.value;
    setFullName(v);
  }, []);

  const handleEdit = () => {
    setEditMode(true);
  };

  const onCopyLink = useCallback(
    (str: string) => {
      if (!str) return;
      const b = copy(str);
      if (b) {
        Toast.success(commonT('复制成功'));
      }
    },
    [commonT],
  );

  const handleSubmit = async () => {
    let params: UpdateProfileReq = {
      studentId: uuid,
      fullName: fullNameState,
      englishName: englishNameState,
      currentGrade: currentGradeState !== null ? currentGradeState : undefined,
      currentAge: Number(currentAgeState),
    };
    if (avatarUrl) {
      params = { ...params, data: { HeadImageUrl: avatarUrl } };
    }
    if (selectZone) {
      params = {
        ...params,
        timezoneStd: selectZone,
        timezone: timezoneList.find((item) => item.value === selectZone)?.label,
        displayTimezone: selectZone,
      };
    }
    if (!fullNameState || !englishNameState) {
      Toast.warning(commonT('昵称不能为空'));
      return;
    }
    if (fullNameState.length < 2 || englishNameState.length < 2) {
      Toast.warning(commonT('昵称至少2个字符'));
      return;
    }
    if (/[\u4e00-\u9fa5]/.test(englishNameState)) {
      Toast.warning(commonT('请填写正确的英文昵称'));
      return;
    }
    try {
      setSubmitLoading(true);
      await updateProfile(params);
      setSubmitLoading(false);
      setEditMode(false);
      onGetStudents();
      log(prefixName`save_success`);
      Toast.success(t('保存成功'));
    } catch {
      setSubmitLoading(false);
    }
  };

  const handleOpenUpload = () => {
    if (!avatarUrl) {
      setTempUrl('');
    }
    setModalVis(true);
  };

  const handleClickUpload = () => {
    fileRef!.current!.value = '';
    fileRef?.current?.click();
  };

  const handleFileChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (tempUrl) {
      (window.URL || webkitURL).revokeObjectURL(tempUrl);
    }
    const { files = [] } = target || {};
    const file = files?.[0];
    if (file && file?.size > MAX_FILE_SIZE) {
      Toast.warning(t('图片文件过大, 无法上传'));
    } else if (file) {
      const url = (window.URL || webkitURL)?.createObjectURL(file);
      setTempUrl(url);
    }
  };

  const handleUpload = () => {
    setModalSubmitLoading(true);
    cropper?.getCroppedCanvas()?.toBlob(async (blob) => {
      if (blob) {
        const file = new File([blob], `file.${blob?.type?.split('/').pop()}`);
        const { url } = await uploadFile(file);
        setAvatarUrl(url);
        setModalSubmitLoading(false);
        setModalVis(false);
      }
    });
  };

  const formatTimezoneList = useMemo(() => {
    return timezoneList.map((item) => {
      const index = item.label?.indexOf(')');
      return { value: item.value, label: `${item.label?.substring(0, index + 1)} ${item.value}` };
    });
  }, [timezoneList]);

  const memorizedHandleEditModeChange = useCallback(() => {
    setEditMode(false);
    setAvatarUrl('');
    setCurrentAge(currentAge);
    setFullName(fullName);
    setEnglishName(englishName);
    setCurrentGrade(currentGrade);
    setSelectZone(timezoneStd);
  }, [currentAge, currentGrade, englishName, fullName, timezoneStd]);

  useEffect(() => {
    if (!selectZone) return;
    getGradeMapping(selectZone).then((res) => {
      if (res.content) {
        const ops = res.content.map((item) => {
          return {
            label: i18n.language === 'en' ? item.gradeEn : item.gradeZh,
            value: item.grade,
          };
        });
        setRegionMapping(ops);
      }
    });
  }, [i18n.language, selectZone]);

  return (
    <>
      <CustomModal bodyClassName={styles.modal} onClose={() => setModalVis(false)} visible={modalVis}>
        <span className={styles.modalTitle}>{t('上传头像')}</span>
        <div className={styles.modalBody}>
          {tempUrl ? (
            <Cropper
              initialAspectRatio={1}
              preview={previewRef?.current as HTMLDivElement}
              src={tempUrl}
              viewMode={1}
              zoomOnWheel={false}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              aspectRatio={1 / 1}
              className={styles.cropper}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides
            />
          ) : (
            <div className={styles.upload} role="button" onClick={handleClickUpload}>
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="35" width="80" height="10" rx="5" fill="#EEEEEE" />
                <rect x="45" width="80" height="10" rx="5" transform="rotate(90 45 0)" fill="#EEEEEE" />
              </svg>
              {t('点击上传图片')}
            </div>
          )}
          {modalSubmitLoading && <div className={styles.mask} />}
          <div className={styles.preview}>
            <div className={cls(styles.previewAvatar, tempUrl && styles.previewAvatarShow)} ref={previewRef} />
            <span>{t('预览头像')}</span>
          </div>
        </div>
        <div className={styles.modalOperate}>
          <Button color="blue" disabled={!tempUrl} onClick={handleClickUpload}>
            {t('换一张')}
          </Button>
          <Button disabled={!tempUrl} loading={modalSubmitLoading} onClick={handleUpload} color="green">
            {t('确定')}
          </Button>
        </div>
      </CustomModal>
      <div
        className={cls(styles.profileInfo, editMode && styles.profileInfoEdit)}
        id={`${STUDENT_LIST}${curStudentUUid}`}
      >
        <div className={styles.left}>
          <div className={styles.avatarWrapper}>
            <div className={styles.avatar}>
              <img style={{ width: '100%' }} src={avatarUrl || data.HeadImageUrl || avatar.src} alt="avatar" />
              {editMode && (
                <div className={styles.avatarHover} onClick={handleOpenUpload}>
                  <CameraIcon />
                </div>
              )}
            </div>
            {isCurrentStudent && (
              <p className={cls(styles.currentStu, i18n?.language === 'en' && styles.en)}>{t('当前学员')}</p>
            )}
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.item}>
              <div className={styles.itemTitle}>{t('中文昵称')}</div>
              <div className={styles.itemTitle}>{t('学号')}</div>
              <div className={styles.itemTitle}>{t('所在时区')}</div>
            </div>
            <div className={styles.item}>
              {!editMode ? (
                <div className={styles.itemValue}>{fullName}</div>
              ) : (
                <Input
                  maxLength={50}
                  placeholder={commonT('占位符请输入')}
                  onChange={onFullNameChange}
                  value={fullNameState}
                  className={styles.itemInput}
                  style={{ padding: '0 10px' }}
                />
              )}
              <div className={styles.itemValue}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '12px' }}>{code}</span>
                  <CopyIcon onClick={() => onCopyLink(code)} />
                </div>
              </div>
              <div className={cls(styles.itemValue, editMode && styles.isEdit)}>
                {editMode ? (
                  <WKSelect
                    value={selectZone}
                    onChange={memorizedHandleTimezoneChange}
                    search
                    options={formatTimezoneList}
                    defaultOpen={editMode && isCurrentStudent && anchor === STUDENT_LIST}
                  />
                ) : (
                  <p
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    title={formatTimezoneList?.find(({ value }) => value === timezoneStd)?.label || ''}
                  >
                    {formatTimezoneList?.find(({ value }) => value === timezoneStd)?.label || ''}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.item}>
              <div className={styles.itemTitle}>{t('英文昵称')}</div>
              <div className={styles.itemTitle}>{t('所在年级')}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemValue}>
                {!editMode ? (
                  <div>{englishName || t('暂无')}</div>
                ) : (
                  <Input
                    onChange={onEnglishNameChange}
                    maxLength={50}
                    placeholder={commonT('占位符请输入')}
                    value={englishNameState}
                    className={styles.itemInput}
                    style={{ padding: '0 10px' }}
                  />
                )}
              </div>
              <div className={cls(styles.itemValue, editMode && styles.isEdit)}>
                {!editMode ? (
                  <div>{(i18n.language === 'en' ? displayGradeEn : displayGradeZh) || t('暂无')}</div>
                ) : (
                  <WKSelect
                    placeholder={commonT('占位符请选择')}
                    options={regionMapping}
                    defaultValue={currentGradeState !== null ? currentGradeState : undefined}
                    onChange={memorizedHandleDisplayGradeChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          className={styles.file}
          ref={fileRef}
          onChange={handleFileChange}
        />
        {editMode ? (
          <div className={styles.operate}>
            <WKButton onClick={memorizedHandleEditModeChange}>{commonT('取消')}</WKButton>
            <WKButton
              loading={submitLoading}
              type={'primary'}
              onClick={handleSubmit}
              disabled={
                !selectZone &&
                !avatarUrl &&
                !(englishNameState && fullNameState && currentAgeState && currentGradeState)
              }
            >
              {commonT('保存')}
            </WKButton>
          </div>
        ) : (
          <div onClick={handleEdit} role="button" className={styles.edit}>
            <Image width={48} height={48} src={edit.src} alt="" />
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileInfo;
